
import { Action, Getter } from "vuex-class";
import { Component, Ref, Mixins } from "vue-property-decorator";
import { FormBase, FormTextarea, FormSubmit } from "@/components/forms";
import { bus } from "@/main";
import { WorkStatus } from "@/store/modules/activity.store";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { activityIsDossier } from "@/utils/model-helpers";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormSubmit,
        FormTextarea,
    },
})
export default class CloseActivityModal extends Mixins(HasFormErrors) {
    @Getter("activity/viewing") activity!: Activity;
    @Action("activity/patch") patchActivity!: (payload: ActivityPatchPayload) => Activity;

    @Ref() form!: FormClass;

    show = false;

    payload = {
        lost_reason: "",
    };

    get isDossier() {
        return activityIsDossier(this.activity);
    }

    mounted() {
        bus.$off("show-close-activity");
        bus.$on("show-close-activity", () => {
            this.show = true;
        });
    }

    async submit(form: FormClass) {
        try {
            const payload = {
                id: this.activity.id,
                status: "CLOSED",
            };
            if (!this.isDossier && this.activity.work_status !== WorkStatus.lost) {
                //@ts-ignore
                payload.work_status = WorkStatus.lost;
                //@ts-ignore
                payload.lost_reason = this.payload.lost_reason;
            }

            await this.patchActivity(payload);

            this.$toast.open({ message: this.$t("views.prospects.closed") as string, type: "success", position: "bottom-right" });

            this.handleClose();
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        } finally {
            form.reset();
        }
    }

    handleClose() {
        this.clearErrors();

        this.show = false;
    }
}
